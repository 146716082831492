<template>
    <div class="container page-content">
        <div>
            <div class="section-s">
                <div class="form-label">
                    Choose the data source
                    <div class="grid">
                        <div class="col-auto">
                            <select
                                id="sources"
                                v-model="selectedSource"
                                class="select fit"
                            >
                                <option :value="null">
                                    All
                                </option>
                                <option v-for="(source, index) in sources" :key="index" :value="source">
                                    {{ source }}
                                </option>
                            </select>
                        </div>
                        <div class="col-auto">
                            <UploadEphem tabname="fcp" />
                        </div>
                    </div>
                </div>
            </div>
            <FleetControlPanelViz
                v-if="!isFleetEmpty"
                :data="eventsBySatellite"
                :max-distance="maxDistance"
                :selected-date="selectedDate"
                class="section"
                @onUpdateDate="updateDate"
                @onMarkerClick="openPanel"
            />
            <div v-if="isFleetEmpty" class="no-data-msg">
                <DateSwitcher :date="selectedDate" @onUpdateDate="updateDate" />
                <hr class="line-separator">
                <p>No close approach event was detected for the next five days. Cataloged objects are not threatening your satellite(s) at the moment. </p>
                <hr class="line-separator">
            </div>
            <transition name="slide">
                <PopinPanel v-if="selectedEvent" :event="selectedEvent" :max-distance="maxDistance" @onClose="openPanel(null)" />
            </transition>
        </div>
    </div>
</template>

<script>
import FleetControlPanelViz from "./FleetControlPanelViz";
import PopinPanel from "./PopinPanel";
import {mapActions, mapMutations, mapState} from "vuex"
import DateSwitcher from "@/components/DateSwitcher";
import UploadEphem from "./UploadEphem.vue";

export default {
    name: 'FleetPanelView',
    components: {
        DateSwitcher,
        FleetControlPanelViz,
        PopinPanel,
        UploadEphem
    },
    props: {
        maxDistance: {
            type: Number,
            required: true,
        }
    },
    data() {
        return {
            selectedSource: null,
            selectedEvent: null,
        }
    },
    computed: {
        ...mapState('event', ['fleet', 'sources', 'events', 'selectedDate']),
        eventsBySatellite() {
            const fleet = this.fleet;
            const eventsBySatellite = [];

            for (const satellite of fleet) {
                eventsBySatellite.push({
                    name: satellite.name,
                    sat_id: satellite.sat_id,
                    events: []
                })
                for (const event of satellite.Events) {
                    let sourceT = event.Conjunctions ?
                        event.Conjunctions[0].Scenario.Ephem_source.source_name
                        : (event.object1_ephemeris_name !== "NONE" ?
                            event.object1_ephemeris_name : event.originator);
                    let sourceC = event.Conjunctions ?
                        event.Conjunctions[0].ScenarioC.Ephem_source.source_name
                        : (event.object2_ephemeris_name !== "NONE" ?
                            event.object2_ephemeris_name : event.originator);

                    // filter events according to selected data source
                    if (!!this.selectedSource && sourceT !== this.selectedSource && sourceC !== this.selectedSource) {
                        continue;
                    }

                    if (!event.cdm_id) {
                        if (event.Conjunctions[0].miss_distance <= this.maxDistance) eventsBySatellite[eventsBySatellite.length - 1].events.push({
                            ...event.Conjunctions[0],
                            obj: event.Object.name,
                            obj_id: event.Object.sat_id,
                            sourceT: sourceT,
                            sourceC: sourceC,
                        })
                    } else if (event.miss_distance <= this.maxDistance) {
                        /** external event coming from ccsds cdm operator, it's an event like object but has no conjunction
                        and a cdm_id */
                        eventsBySatellite[eventsBySatellite.length - 1].events.push({
                            ...event,
                            event_id: event.cdm_id,
                            obj: (event.Object && event.Object.name) ? event.Object.name : 'Analyst Object',
                            obj_id: event.Object ? event.Object.sat_id : event.object2_object_designator,
                            sourceT: sourceT,
                            sourceC: sourceC,
                        })
                    }
                }
            }
            return eventsBySatellite.filter(sat => sat.events.length)
        },
        isFleetEmpty() {
            for (const satellite of this.eventsBySatellite ) {
                if (satellite.events.length > 0) {
                    return false;
                }
            }
            return true;
        }
    },
    methods: {
        ...mapActions('event', ['loadFleet']),
        ...mapMutations('event', ['setSelectedDate']),
        openPanel(eventId) {
            const clickedEvent = this.findEventById(eventId)
            this.selectedEvent = this.selectedEvent === clickedEvent ? null : clickedEvent;
        },
        findEventById(eventId) {
            let event;
            for (let satellite of this.fleet) {
                event = satellite.Events.find(event => event.event_id === eventId || event.cdm_id === eventId)
                if (event) break;
            }
            return event
        },
        updateDate(newDate) {
            this.setSelectedDate(newDate);
            this.loadFleet();
        },
    }
}
</script>
