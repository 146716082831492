<template>
    <div class="auth-layout">
        <div class="layout-content">
            <div class="content-header">
                <a href="https://www.aldoria.com" target="_blank" class="logo" />
                <h1 class="page-title">
                    Reset your password
                </h1>
            </div>

            <div class="content-body">
                <ValidationObserver v-slot="{handleSubmit, errors, invalid}">
                    <form @submit.prevent="handleSubmit(() => resetPwd({guid, newPwd}))">
                        <ValidationProvider
                            v-slot="{classes}"
                            slim
                            rules="required|password"
                            vid="password"
                        >
                            <div class="input-label-container">
                                <label for="password" class="form-label" :class="classes">New password</label>
                                <input
                                    id="password"
                                    v-model="newPwd"
                                    name="New password"
                                    type="password"
                                    class="input"
                                    :class="classes"
                                >
                            </div>
                        </ValidationProvider>
                        <p class="input-tip">
                            8 characters min. including at least one numeric digit and one special character.
                        </p>
                        <ValidationProvider
                            v-slot="{classes}"
                            rules="required|confirmed:password"
                            slim
                        >
                            <div class="input-label-container">
                                <label for="confirmPassword" class="form-label" :class="classes">Password confirmation</label>
                                <input
                                    id="confirmPassword"
                                    v-model="confirmPassword"
                                    name="Password confirmation"
                                    type="password"
                                    class="input"
                                    :class="classes"
                                >
                            </div>
                        </ValidationProvider>
                        <div v-show="invalid" class="form-validation-msgs">
                            <div
                                v-for="(errSlot, slotIndex) in errors"
                                :key="slotIndex"
                            >
                                <p v-for="(error, index) in errSlot" :key="index" class="text-error">
                                    {{ error }}
                                </p>
                            </div>
                        </div>
                        <div class="form-actions">
                            <button class="btn btn-1">
                                Reset password
                            </button>
                            <div>
                                <router-link class="link link-2" :to="{name: 'app.public.login'}">
                                    &lt;&nbsp;Back to login
                                </router-link>
                            </div>
                        </div>
                    </form>
                </ValidationObserver>
            </div>
        </div>
        <div class="layout-image" />
    </div>
</template>

<script>
import {ValidationObserver, ValidationProvider} from 'vee-validate';
import {mapActions} from "vuex";

export default {
    name: "ResetPassword",
    components: {
        ValidationProvider,
        ValidationObserver,
    },
    props: {
        guid: {
            type: String,
            required: true,
        }
    },
    data() {
        return {
            newPwd: '',
            confirmPassword: ''
        }
    },
    methods: {
        ...mapActions({
            resetPwd: 'auth/resetPassword',
        }),
    }
}
</script>
