import app from './main';

const duration = 5000;

export default {
    success(title, text) {
        this.notify(title, text, 'success');
    },

    warn(title, text) {
        this.notify(title, text, 'warn');
    },

    error(title, text) {
        this.notify(title, text, 'error');
    },

    notify(title, text, type) {
        app.$notify({
            title,
            text,
            type,
            duration
        });
    },
}
